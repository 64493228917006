<template>
  <div>
    <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
    <v-card v-if="data_loaded">
      <v-card-title>
        <div class="d-flex align-center">
          <v-icon x-large>group</v-icon>
          <h1 class="ms-3">לקוחות</h1>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          @keyup="search_customer($event)"
          label="بحث"
          outlined
          dense
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          class="text-center"
          :headers="headers"
          :items="items"
          :mobile-breakpoint="0"
          :hide-default-footer="true"
          :disable-pagination="true"
        >
          <template v-slot:item.photo="{ item }">
            <img
              v-if="item.photo"
              :src="`${$store.state.def_path}/${item.photo}`"
              width="50"
              height="50"
              alt=""
            />
            <img
              width="50"
              height="50"
              v-if="!item.photo"
              :src="`${$store.state.def_path}/uploads/none_img.png`"
              alt=""
            />
          </template>
          <template v-slot:item.wallet="{ item }">
            {{ item.wallet ? "₪" + item.wallet : "__" }}
          </template>
          <template v-slot:item.is_special="{ item }">
            <div class="d-flex justify-center" style="width: 100%">
              <v-switch
                :disabled="specialLoading"
                :loading="specialLoading"
                hide-details
                small
                v-model="item.is_special"
                @change="spcialDialog(item)"
              ></v-switch>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="$store.getters.permission('customer_orders access')"
              icon
              small
              :to="`customer_orders/${item.id}`"
            >
              <v-icon small>visibility</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <template>
      <div
        style="margin-bottom: 100px"
        v-if="data_loaded"
        class="text-center mt-4 d-flex justify-center"
      >
        <v-pagination
          @input="get_data()"
          v-model="page"
          total-visible="7"
          :length="total"
          circle
        ></v-pagination>
      </div>
    </template>

    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-text class="pt-4">
          <h2 class="text-center font-weight-bold text-h5">change spcial</h2>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            @click="changeSpecial()"
            :loading="specialLoading"
            :disabled="specialLoading"
            color="primary"
            >save</v-btn
          >
          <v-btn :disabled="specialLoading" @click="close_dialog(true)"
            >cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar color="#333" left v-model="snackbar_success">
      <p class="white--text font-weight-bold">עודכן בהצלחה</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: [],
  data() {
    return {
      snackbar_success: false,
      data_loaded: false,
      search_changed: false,
      search: "",
      headers: [
        {
          value: "name",
          text: "שם",
          align: "center",
        },
        {
          value: "photo",
          text: "תמונה",
          align: "center",
        },
        {
          value: "wallet",
          text: "wallet",
          align: "center",
        },
        {
          value: "is_special",
          text: "is_special",
          align: "center",
        },
        {
          value: "actions",
          text: "פעולות",
          align: "center",
        },
      ],
      dialog: false,
      items: [],
      total: 0,
      page: 1,
      item: null,
      specialLoading: false,
    };
  },
  watch: {
    search() {
      this.search_changed = true;
    },
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `customers?page=${this.page}&search_key=${this.search}`,
            method: "get",
          },
        })
        .then((res) => {
          if (this.search_changed) {
            this.search_changed = false;
          }

          this.items = Object.assign([], res.data.data.data);
          this.total = res.data.data.last_page;
          this.page = res.data.data.current_page;
          this.data_loaded = true;
        })
        .catch((err) => {});
    },
    search_customer(key) {
      if (key.key == "Enter") {
        this.get_data();
        if (this.search_changed) {
          this.page = 1;
        }
      } else if (key.key == "Backspace" && !this.search_key) {
        this.get_data();
        if (this.search_changed) {
          this.page = 1;
        }
      }
    },
    updateItemSpecial(status) {
      let find = this.items.find((e) => e.id == this.item.id);
      find.is_special = status;
    },
    close_dialog(close = false) {
      if (close) {
        this.updateItemSpecial(!this.item.is_special);
      }
      this.dialog = false;
      this.item = null;
      this.specialLoading = false;
    },
    spcialDialog(item) {
      this.item = item;
      this.dialog = true;
    },
    changeSpecial(ev) {
      if (!this.specialLoading) {
        this.specialLoading = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `customers/special/${this.item.id}`,
              method: "put",
            },
          })
          .then((res) => {
            this.updateItemSpecial(res.data.is_special);
            this.close_dialog();
          });
      }
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss" scoped>
.basic {
  .col {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
