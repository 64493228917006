<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <Table
          ref="table"
          @close_form="close_form()"
          @update_data="get_data()"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import Table from "@/components/customers/table";
export default {
  name: "employees_page",
  data() {
    return {};
  },
  components: {
    Table,
  },

  methods: {
    update_table() {
      this.$refs.table.get_data();
    },
    close_form() {
      if (this.$refs.form) {
        this.$refs.form.close_form();
      }
    },
  },
  mounted() {},
};
</script>
